$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; $latest_avatar_size: unquote("small");$topic_avatars_size: unquote("45");$header_avatars_size: unquote("32");$header_height: unquote("60");$margin_top: unquote("0");$avatars_border_radius: unquote("50%");$mobile_avatars_size: unquote("45");// Theme: Discourse Avatar Component
// Target: common scss
// Last Edited: 2024-08-02 08:04:13 UTC
// Avatars on topics
.topic-avatar {
  width: #{$topic_avatars_size}px;
}

.embedded-posts .topic-avatar {
  padding-left: 25px;
  padding-top: 15px;
  width: 45px;

  img.avatar {
    width: 45px;
    height: 45px;
  }
}

// Avatar on header
#current-user a.icon {
  width: #{$header_avatars_size}px !important;
  background: transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
}

.d-header-icons .icon img.avatar {
  width: #{$header_avatars_size}px !important;
  height: #{$header_avatars_size}px !important;
}

.d-header {
  height: #{$header_height}px;
}

// Avatars shape (round = 50%, square = 0%)
.avatar {
  border-radius: #{$avatars_border_radius} !important;
}

// Avatars on /latest
.topic-list .posters {
  width: unset;

  &.theme-avatar-large,
  &.theme-avatar-extra_large,
  &.theme-avatar-huge {
    width: auto;

    a:not(.latest) {
      display: none;
    }
  }

  &.theme-avatar-tiny,
  &.theme-avatar-small {
    width: 146px;
  }

  &.theme-avatar-medium {
    width: 176px;
  }
}

// Space from header
.list-controls,
.admin-main-nav,
.mobile-view .admin-main-nav,
.alert,
#banner,
.user-main,
#topic-title,
.about-page .nav-pills,
.static-faq .nav-pills,
.static-tos .nav-pills,
.static-privacy .nav-pills,
.alert.alert-info,
.container.badges h1,
.users-page .directory,
.mobile-view .user-controls .period-chooser,
.groups-header-filters {
  margin-top: #{$margin_top}px;
}
// Theme: Discourse Avatar Component
// Target: desktop scss
// Last Edited: 2024-05-17 10:02:00 UTC
// Transparent background for the header avatar
.d-header-icons {
  .current-user .icon {
    &:active,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

// Notifications babble
.d-header-icons {
  .unread-notifications {
    right: -6px;
  }
  .unread-private-messages {
    right: auto;
    left: -10%;
  }
}
